.h3 {
	text-transform: uppercase;
	font-weight: 600;
	font-size: 1.6rem;
	position: relative;
	display: inline-block;
	cursor: pointer;
	letter-spacing: 2px
}
.h3:after {
		content:'';
		width: 1.5rem;
		height: 1rem;
		background: url('/images/arrow-top.svg') no-repeat center;
		background-size: contain;
		position: absolute;
	    right: -2.5rem;
	    top: .5rem;
	}
.h3.show:after {
		background: url('/images/arrow-bottom.svg') no-repeat center;
		top: .7rem;
	}
.filters {
	background: #FAFAFA;
	padding: 2rem;
	margin-bottom: 4.8rem
}
.filters .list li {
		text-transform: uppercase;
		display: inline-block;
		margin: 2rem 2rem 0 0;
		cursor: pointer;
		font-weight: 500
	}
.filters .list li.selected {
			background: #F2F255;
			padding: .5rem;
		}
.projects {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-bottom: 1.6rem;
	margin-right: calc(3.2rem * -1)
}
.projects>* {
		width: 100%;		
		max-height: 800px;
		padding-right: 3.2rem
	}
@media (min-width: 801px) {
.projects>* {
			max-height: 600px;
			max-width: 33.33%
	}
		}
@media (min-width: 801px) and (max-width: 1151px) {
.projects>* {
			max-width: 50%
	}
		}
.pagination {
	display: flex;
	justify-content: center
}
.pagination ul {
	    display: flex;
		position: relative
	}
.pagination ul li {
	    	font-weight: 600;
	    	padding: 1rem 2rem;
			cursor: pointer
	    }
.pagination ul li:hover {
				color: #DAD978;
			}
.pagination .selected {
		border-bottom: solid 4px #DEDD19;
	}
.pagination .arrow {
		width: 2rem;
		height: 1.5rem;
		margin-bottom: 0;
		padding: 0;
	    position: absolute;
		top: 1.4rem;
		right: -2rem
	}
.pagination .arrow.next {
			background: url('/images/arrow-right.svg') no-repeat center;
			background-size: contain
		}
.pagination .arrow.next:hover {
				background: url('/images/arrow-right-hover.svg') no-repeat center;
			}
.pagination .arrow.prev {
			background: url('/images/arrow-left.svg') no-repeat center;
			background-size: contain;	
			left: -2rem;
			right: inherit
		}
.pagination .arrow.prev:hover {
				background: url('/images/arrow-left-hover.svg') no-repeat center;
			}
