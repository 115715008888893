
	.root .pagination-slider {
		display: flex;
		align-items: center
	}
@media (max-width: 800px) {
		.root .pagination-slider {
			justify-content: center
	}
		}
.root .bullet {
		background: #C3C3C3;
	    width: 7px;
	    height: 7px;
	    border-radius: 100%;
	    margin: 0 .5rem;
	    border: none
	}
.root .bullet.selected {
			background: #1F1410;
			width: 17px;
			border-radius: 5rem;
		}
.root .bullet:hover {
			background: #DAD978;
		}
.root :global(.slider .slider-control-topright) {
		top: -25px !important
	}
@media (max-width: 800px) {
		.root :global(.slider .slider-control-topright) {
			position: inherit !important;
			margin-bottom: 4.8rem
	}
		}
.root :global(.slider .slider-slide img) {
		border-radius: 1rem;
	    display: block;
	    max-width: 100%;
	}
.root :global(.slider .slider-control-bottomcenter) {
		bottom: -30px !important;
	}
.root .arrow {
		width: 2rem;
        height: 1.2rem;
        border: none
	}
.root .arrow.first-selected, .root .arrow.last-selected {
        	opacity: 0;
        }
.root .arrow-left {
        background: url('/images/arrow-left.svg') no-repeat center;
        background-size: contain
	}
.root .arrow-left:hover {
        	background: url('/images/arrow-left-hover.svg') no-repeat center;
        }
.root .arrow-right {
        background: url('/images/arrow-right.svg') no-repeat center;
        background-size: contain
	}
.root .arrow-right:hover {
        	background: url('/images/arrow-right-hover.svg') no-repeat center;
        }