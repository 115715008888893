.h2 {
    font-family: 'Work Sans', sans-serif;
    margin-bottom: 1.6rem;
    color: #1F1410;
    font-size: 4rem;
    text-transform: uppercase;
    text-align: center;
}
.content {
    display: flex;
    align-items: center;
    flex-direction: column
}
.content .photo {
        width: 100%;
        order: 2;
    }
@media (min-width: 801px) {
.content {
        flex-direction: row
}
        .content .photo {
            order: 0;
        }
        .content > div {
            flex: 1 1 0;
        }
        .content div + div {
            margin-left: 3.2rem;
        }
    }
.content .error {
        height: 40rem;
        background: no-repeat center;
        background-size: contain;
        background-image: url('/images/error-404.jpg');
        margin-bottom: 4.8rem;
    }
.content .text {
        font-size: 2.5rem;
        text-align: center;
        line-height: 1.5;
    }