
	@media (min-width: 801px) {
.content {
		display: flex
}
	}
.content {
    align-items: center
}
.content div {
		flex: 1 1 auto;
		text-align: center;
		margin-bottom: 3.2rem;
	}
.content .link {
		font-weight: 600;
		position: relative;
    	letter-spacing: 3px
	}
.content .link:after {
			content:'';
			width: 2rem;
			height: 1rem;
			background: url('/images/arrow-right.svg') no-repeat center;
			background-size: contain;
			position: absolute;
			right: -2.5rem;
			top: .4rem;
		}
.content .link:hover:after {
			background: url('/images/arrow-right-hover.svg') no-repeat center;
		}
.sub-title {
	font-size: 2.8rem;
	font-weight: 600;
	margin-bottom: 3.2rem;
}
.text {
	margin-bottom: 0.4rem;
	font-weight: 500;
}
.social-media {
	text-align: center;
	align-items: center;
	display: flex;
	flex-direction: column
}
@media (min-width: 801px) {
.social-media {
		flex-direction: row
}
		.social-media > a:last-child {
			margin-left: 3.2rem;
		}
	}
.social-media {
	justify-content: center;
	font-size: 1.8rem;
	padding: 4.8rem 0
}
.social-media > a {
		font-weight: 600;
		text-transform: uppercase;
		display: block;
		text-align: center;
		margin-bottom: 3.2rem;
		padding-left: 3.2rem;
	}
.photo {
	background: url('/images/contacto.jpg') no-repeat center top;
    width: 100%;
    height: 40rem;
    background-size: contain
}
@media (min-width: 801px) {
.photo {
    	height: 90rem
}
    }
.link.instagram {
		background: url('/images/instagram-logo-dark.svg') no-repeat left
	}
.link.instagram:hover {
			background: url('/images/instagram-logo-dark-hover.svg') no-repeat left;
		}
.link.facebook {
		background: url('/images/facebook-logo-dark.svg') no-repeat left
	}
.link.facebook:hover {
			background: url('/images/facebook-logo-dark-hover.svg') no-repeat left;
		}