.footer {
	background-color: #000;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #fff;
    padding: 2rem 0
}
.footer .container {
	    margin: 0 auto;
	    padding: 0 2rem
	}
@media (min-width: 801px) {
.footer .container {
		    display: flex
	}
	    }
@media (max-width: 800px) {
.footer .container {
	        width: 100%;
	        padding-bottom: 0
	}
	    }
.address li {
		font-weight: 500;
		font-size: 1.8rem;
		margin-bottom: 0
	}
.address li.space {
			margin: 1.6rem 0;
		}
.made-by {
	color: #fff;
	font-size: 1.6rem
}
@media (min-width: 801px) {
.made-by {
		margin-bottom: 0
}
	}
.made-by .name {
		font-weight: 600;
	}
.footer-left {
	flex-basis: 25%;
	padding: 3rem 0;
	text-align: center
}
@media (min-width: 801px) {
.footer-left {
		text-align: left
}
	}
.footer-center {
	flex-basis: 50%;
	text-align: center
}
@media (min-width: 801px) {
.footer-center {
	    display: flex;
	    flex-direction: column;
	    justify-content: space-between;
    	padding-top: 4rem
}
    }
@media (max-width: 800px) {
.nav-list {
		margin-bottom: 4rem
}
	}
.nav-list li {
	font-weight: 600;
	font-size: 1.6rem;
	display: inline-block;
	position: relative;
	margin-bottom: 0
}
@media (max-width: 800px) {
.nav-list li {
		display: block;
		margin: 2rem 0
}
	}
.nav-list li a {
		padding: 0 .5em;
	}
@media (min-width: 801px) {
.nav-list li + li:before {
			height: 10px;
			width: 2px;
			background:  #FFF;
			content: '';
			position: absolute;
			top: 3px;
			left: 0
	}
		}
.copyright {
	color: #fff;
	font-size: 1.6rem;
	margin-bottom: 0;
}
.social-media {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    background: no-repeat center;
    background-size: contain;
    margin-bottom: 2rem
}
.social-media.facebook {
    	background-image: url('/images/facebook-logo.svg')
    }
.social-media.facebook:hover {
    		background-image: url('/images/facebook-logo-hover.svg');
    	}
.social-media.instagram {
    	background-image: url('/images/instagram-logo.svg');
    	margin-left: 2em
    }
.social-media.instagram:hover {
    		background-image: url('/images/instagram-logo-hover.svg');
    	}
