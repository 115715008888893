.root {
	margin: 4.8rem auto 24rem;
	max-width: 100%
}
.root .pagination-slider {
		display: flex;
		align-items: center;
	}
.root .bullet {
		background: #C3C3C3;
	    width: 7px;
	    height: 7px;
	    border-radius: 100%;
	    margin: 0 .5rem;
	    border: none
	}
.root .bullet.selected {
			background: #1F1410;
			width: 17px;
			border-radius: 5rem;
		}
.root .bullet:hover {
			background: #DAD978;
		}
.root :global(.slider .slider-slide.slide-visible) {
		z-index: 10;
	}
.root :global(.slider .slider-slide.slide-visible > div) {
		text-align: center;
	}
.root :global(.slider .slider-list) {
		display: flex !important;
		justify-content: center;
		align-items: center;
	}
.root :global(.slider .slider-slide) {
		top: auto !important;
	}
.root :global(.slider .slider-slide> *) {
		border-radius: 1rem;
		max-width: 100%;
		max-height: 100vh;
		overflow: hidden;
	}
.root :global(.slider .slider-control-bottomcenter) {
		bottom: -30px !important;
	}
.root .arrow {
		width: 2rem;
        height: 1.2rem;
        border: none
	}
.root .arrow.first-selected, .root .arrow.last-selected {
        	opacity: 0;
        }
.root .arrow-left {
        background: url('/images/arrow-left.svg') no-repeat center;
        background-size: contain
	}
.root .arrow-left:hover {
        	background: url('/images/arrow-left-hover.svg') no-repeat center;
        }
.root .arrow-right {
        background: url('/images/arrow-right.svg') no-repeat center;
        background-size: contain
	}
.root .arrow-right:hover {
        	background: url('/images/arrow-right-hover.svg') no-repeat center;
        }
.root .slide {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center
	}
.root .slide>*{
			width: 100%;
		}
.root :global(.slider-control-centerright) {
	    top: 0 !important;
	    right: 0;
	    height: 100%;
	    transform: none !important;
	    display: flex;
	}
.root .carousel-right, .root .carousel-left {
		width: 50px;
		height: 100%;
		border: none
	}
.root .carousel-right.first-selected, .root .carousel-right.last-selected, .root .carousel-left.first-selected, .root .carousel-left.last-selected {
        	opacity: 0;
        }
.root :global(.slider-control-centerleft) {
	    top: 0 !important;
	    left: 0;
	    height: 100%;
	    transform: none !important;
	    display: flex;
	}
