@import url('https://fonts.googleapis.com/css?family=Oswald|Raleway:400,500,600&display=swap');

.app {
    position: relative;
    min-height: 100vh;
    font-family: 'Raleway', sans-serif;
    font-size: 1.4rem;
}

.main-content {
    padding-top: 18rem;
    min-height: 100%;
    position: absolute;
    width: 100%;
    padding-bottom: 26rem
}

.main-content section ul {
        list-style-type: none;
    }

@media (min-width: 801px) and (max-width: 1151px) {

.main-content {
        padding-top: 15rem
}
    }

@media (max-width: 800px) {

.main-content {
        padding-bottom: 59rem
}
    }

@media (max-width: 800px) {

.main-content { padding-top: 14rem
} }

.container {
    max-width: 86%;
    margin: 0 auto;
    padding-bottom: 3.2rem
}

@media (max-width: 800px) {

.container {
        width: 100%;
        max-width: inherit
}
    }

.content {
    padding: 4rem
}

@media (max-width: 800px) {

.content {
        padding: 4rem 2rem
}
    }

.link {
    color: #A4212A;
    word-break: break-word
}

.link:hover {
        color: #1F1410;
    }

.banner {
    position: relative;
    padding-bottom: 30%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover
}

@media (max-width: 800px) {

.banner {
        height: 30rem
}
    }

.h2 {
    font-family: 'Work Sans', sans-serif;
    margin-bottom: 1.6rem;
    color: #1F1410;
    font-size: 4rem;
    text-transform: uppercase;
}

.h3 {
    font-family: 'Work Sans', sans-serif;
    margin-bottom: 1.6rem;
    font-size: 2.8rem;
    font-weight: 500;
    text-transform: uppercase;
}

.search-box {
    margin: 3.2rem 0 3.2rem 2rem
}

@media (min-width: 801px) {

.search-box {
        display: none
}
    }

.button-primary {
    display: inline-block;
    background: #FFF;
    border: solid 1px #1F1410;
    padding: 1.6rem 3.2rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 1.4rem;
    margin-bottom: 1.6rem;
    cursor: pointer
}

.button-primary:hover {
        background: #1F1410;
        color: #FFF;
    }

.button-primary.fill {
        background: #1F1410;
        color: #FFF
    }

.button-primary.fill:hover {
            background: #FFF;
            color: #1F1410;
        }
