.root {
	margin: 4.8rem 0
}
@media (max-width: 800px) {
	.root {
		margin: var(--gap-gl) 0
}
	}
.root .pagination-slider {
		display: flex;
		align-items: center
	}
@media (max-width: 800px) {
	.root .pagination-slider {
			justify-content: center
	}
		}
.root .bullet {
		background: #C3C3C3;
	    width: 7px;
	    height: 7px;
	    border-radius: 100%;
	    margin: 0 .5rem;
	    border: none
	}
.root .bullet.selected {
			background: #1F1410;
			width: 17px;
			border-radius: 5rem;
		}
.root .bullet:hover {
			background: #DAD978;
		}
.root :global(.slider .slider-slide img) {
		display: block;
		max-width: 75%;
		float: right;
	}
.root :global(.slider .slider-control-centerleft) {
		left: 10% !important;
	    top: 90% !important
	}
@media (max-width: 800px) {
	.root :global(.slider .slider-control-centerleft) {
			position: inherit !important;
			left: 0 !important;
			margin-top: 3rem
	}
		}
@media (min-width: 800px) and (max-width: 1420px) {
	.root :global(.slider .slider-control-centerleft) {
			top: 100% !important
	}
		}
.root .arrow {
		width: 2rem;
        height: 1.2rem;
        border: none

	}
.root .arrow.first-selected, .root .arrow.last-selected {
        	opacity: 0;
        }
.root .arrow-left {
        background: url('/images/arrow-left.svg') no-repeat center;
        background-size: contain
	}
.root .arrow-left:hover {
        	background: url('/images/arrow-left-hover.svg') no-repeat center;
        }
.root .arrow-right {
        background: url('/images/arrow-right.svg') no-repeat center;
        background-size: contain
	}
.root .arrow-right:hover {
        	background: url('/images/arrow-right-hover.svg') no-repeat center;
        }
.root a:hover {
	    color: #1F1410;
	}
