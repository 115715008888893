.root {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 18rem;
    background-color: #fff;
    transition: all 400ms ease
}
@media (min-width: 801px) and (max-width: 1152px ) {
.root {
        height: 15rem
}
    }
@media (max-width: 800px) {
.root {
        height: 14rem
}
    }
.root.scroll {
        height: 9.5rem;
    }
.header-desktop {
    display: none
}
@media (min-width: 801px) {
.header-desktop {
        display: block
}
    }
.header-mobile {
    display: none
}
@media (max-width: 800px) {
.header-mobile {
        display: block
}
    }
.navigation {
	display: flex;
    padding: 2rem 4rem;
    margin: 0 auto
}
@media (max-width: 800px) {
.navigation {
        width: 100%;
        padding: 2rem
}
    }
.navigation > div {
    	flex: 1 1 auto;
    }
.navigation > div + div {
    	flex: 2 2 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
@media (max-width: 800px) {
        .navigation .desktop-menu { display: none; }

        .navigation .open {
            background: url('/images/menu.svg') no-repeat center;
            display: block;
            width: 3rem;
            height: 3rem;
            background-size: contain;
            margin-left: 5rem;
            cursor: pointer;
        }
    }
.navigation-scroll {
    display: flex;
    padding: 2rem 4rem;
    margin: 0 auto;
    align-items: center
}
@media (max-width: 800px) {
.navigation-scroll {
        width: 100%
}
    }
.navigation-scroll > div {
        flex: 1 1 auto;
    }
.logo {
    display: block;
    width: 15rem;
    height: 13rem;
    background: url('/images/logo-nuevo-bitamine.png') no-repeat left bottom;
    background-size: contain;
    margin-right: 2rem
}
@media (min-width: 801px) and (max-width: 1152px ) {
.logo {
        width: 10rem;
        height: 10rem;
        margin-right: 1rem
}
    }
@media (max-width: 800px) {
.logo {
        width: 10rem;
        height: 8rem;
        margin-right: 1rem
}
    }
.logo-iso {
    display: block;
    width: 4rem;
    height: 5rem;
    background: url('/images/iso.svg') no-repeat center;
    background-size: contain;
    margin-right: 2rem;
}
.slogan {
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase
}
@media (min-width: 801px) {
.slogan {
        font-size: 1.6rem
}
    }
.logo-content {
    display: flex;
    align-items: flex-end;
}
.bar-content {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
    align-items: center
}
@media (max-width: 800px) {
.bar-content {
        display: none
}
    }
.lang-content {
    margin-left: 3rem
}
.lang-content a {
        color: #C3C3C3;
        text-transform: uppercase;
        font-size: 1.5rem;
        margin-left: 1rem
    }
.lang-content a:hover {
            color: #1F1410;
        }
.buttons-mobile {
    display: flex;
    justify-content: flex-end;
    align-items: center
}
@media (min-width: 801px) {
.buttons-mobile {
        display: none
}
    }
.dropdown .button {
        position: relative;
        font-size: 1.6rem
    }
.dropdown .button:after {
            content:'';
            width: 4rem;
            height: 3rem;
            background: url('/images/arrow-bottom.svg') no-repeat center;
            position: absolute;
            top: -3px;
        }
.dropdown .dropdown-menu {
        position: absolute;
        display: none;
    }
.dropdown:hover .dropdown-menu {
        display: block;
    }
.dropdown .dropdown-item {
        text-transform: uppercase;
        margin-bottom: 0;
    }
.mobile-menu {
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #fff;
    width: 100%;
    display: flex;
    justify-content: center
}
.mobile-menu .logo-content > a {
        display: block;
        position: absolute;
        left: 2rem;
        top: 2rem;
    }
.mobile-menu > nav {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center
    }
.mobile-menu > nav > li {
            font-size: 1.8rem;
        }
.close {
    background: url('/images/icon-close.svg') no-repeat center;
    display: block;
    width: 2rem;
    height: 2rem;
    background-size: contain;
    position: absolute;
    right: 2rem;
    top: 3rem;
    cursor: pointer;
}
