.h2 {
    font-family: 'Work Sans', sans-serif;
    margin-bottom: 1.6rem;
    color: #1F1410;
    font-size: 2.8rem;
    text-transform: uppercase;
    text-align: center;
}
.error {
    width: 50rem;
    height: 40rem;
    background: no-repeat center;
    background-size: contain;
    background-image: url('/images/404.svg');
}
.content {
	display: flex;
	flex-direction: column;
	align-items: center;
}