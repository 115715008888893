.title {
	font-family: 'Work Sans', sans-serif;
	display: inline-block;
	position: absolute;
	left: 2rem;
	top: 30%;
	text-transform: uppercase;
	max-width: 55rem;
	font-size: 2.8rem
}
.title span {
		padding: .5rem;
		background: #F2F255;
		box-shadow: 0.25em 0 0 #F2F255, -0.25em 0 0 #F2F255;
	}
@media (min-width: 801px) {
.title {
		left: 3%;
		font-size: 9rem
}
		.title span {
			padding: 1rem;
		}
    }
@media (min-width: 801px) and (max-width: 1151px) {
.title {
		font-size: 7rem
}
	}
.more {
	font-weight: 600;
	display: block;
	text-align: right;
	text-transform: uppercase;
	letter-spacing: 2px;
	position: relative;
	margin-bottom: 3.2rem
}
.more:after {
        content:'';
        width: 2rem;
        height: 1.2rem;
        background: url('/images/arrow-right.svg') no-repeat center;
        background-size: contain;
        position: absolute;
        right: -2rem;
        top: 3px;
    }
.more:hover:after {
        background: url('/images/arrow-right-hover.svg') no-repeat center;
    }
@media (min-width: 801px) {
	.carousel-desktop {
		display: block;
	}
	.carousel-mobile {
		display: none;
	}
}
@media (max-width: 800px) {
	.carousel-mobile {
		display: block;
	}
	.carousel-desktop {
		display: none;
	}
}
.card{
	max-height: 600px;
}
.cookies-notice {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
    background: #c5c5c5;
    padding: 3.2rem;
    color: #1F1410;
    text-align: center;
    z-index: 100;
    font-size: 1.6rem;
    border-bottom: solid 1px #FFF
}
.cookies-notice .text {
    	margin-bottom: 1.6rem
    }
.cookies-notice .link {
    	text-decoration: underline;
    	font-weight: 600;
    }
.cookies-notice .buttons {
    	display: block;
    }
.cookies-notice .button {
	    display: inline-block;
		border: solid 1px #1F1410;
		padding: 0.8rem 1.6rem;
		text-align: center;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 2px;
		font-size: 1.4rem
    }
.cookies-notice .button:hover {
			background: #1F1410;
			color: #FFF;
		}