.newsletter {
	background: #FAFAFA;
	padding-top: 3.2rem
}
.newsletter a {
		font-weight: 500;
	}
@media (min-width: 801px) {
.fields {
		display: flex;
		align-items: flex-end
}
		.fields > div + div {
			margin-left: 3.2rem;
		}
	}
.fields .field {
		margin-bottom: 1.6rem;
	}
.h2 {
	font-size: 1.8rem;
	font-weight: 500
	/* margin-bottom: var(--gap-md); */
}
@media (max-width: 800px) {
.h2 {
		font-size: 1.4rem
}
	}
.h4 {
	font-size: 2.8rem;
	font-weight: 500;
	margin-bottom: 1.6rem
}
@media (max-width: 800px) {
.h4 {
		font-size: 1.8rem
}
	}
.label {
	display: block;
	margin-bottom: 0.8rem;
}
.input, .select {
	border: solid 1px #1F1410;
	padding: 0 0.8rem;
	min-width: 30rem;
	width: 100%;
	height: 4rem;
}
.button-send {
	width: 100%;
	background: #1F1410;
	color: #FFF;
	padding: 0 4.8rem;
	height: 4rem;
	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 2px;
	font-size: 1.4rem;
	cursor: pointer;
	border: solid 1px #1F1410
}
.button-send:hover {
		background: #FFF;
		color: #1F1410;
	}
.checkbox {
	border: none
}
.checkbox label {
        display: flex;
    }
.checkbox input[type="checkbox"] {
        opacity: 0;
        margin-left: -17px;
    }
.checkbox .check {
        position: relative;
        display: inline-block;
        padding-left: 22px;
        margin: 0;
    }
.checkbox .check::before,
.checkbox .check::after {
    position: absolute;
    content: "";
    display: inline-block;
}
.checkbox .check::before{
    height: 16px;
    width: 16px;
    border: 1px solid #1F1410;
    left: 0;
    top: 4;
}
.checkbox .check::after {
    height: 5px;
    width: 9px;
    border-left: 2px solid #1F1410;
    border-bottom: 2px solid #1F1410;
    transform: rotate(-45deg);
    left: 4px;
    top: 4px;
}
.checkbox input[type="checkbox"] + .check::after {
    content: none;
}
.checkbox input[type="checkbox"]:checked + .check::after {
    content: "";
}
.error {
	color: #A4212A;
	border-color: #A4212A !important
}
.error *, .error *:before {
		border-color: #A4212A !important;
	}
