/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v19/QGYqz_wNahGAdqQ43Rh_eZDlv_1w4A.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v19/QGYqz_wNahGAdqQ43Rh_eZDrv_0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v19/QGYsz_wNahGAdqQ43Rh_cqDpp_k.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v19/QGYsz_wNahGAdqQ43Rh_fKDp.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Gobold';
    src: url('/fonts/Gobold.eot');
    src: url('/fonts/Gobold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Gobold.woff2') format('woff2'),
        url('/fonts/Gobold.woff') format('woff'),
        url('/fonts/Gobold.ttf') format('truetype'),
        url('/fonts/Gobold.svg#Gobold') format('svg');
    font-weight: bold;
    font-style: normal;
}
* {
    margin: 0;
    padding: 0;
    font: inherit;
    line-height: 1.4em;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}
*:hover,
    *:active,
    *:focus {
        outline: none;
    }
html {
    font-size: 55%;
}
body {
    font-family: 'Raleway', sans-serif;
}
a {
    color: inherit;
    text-decoration: none
}
a:hover {
        color: #DAD978;
    }
a,
button {
    transition: all 150ms ease;
}
input,
button,
select {
    padding: 0;
    border-radius: 0;
    background: none;
}
label > div {
    margin: 0.8rem 0;
}
input[type='text'], input[type='date'], input[type='number'] {
    border: 1px solid #1F1410;
    padding: 1rem .5rem;
    width: 100%
}
@media (min-width: 801px) {
input[type='text'], input[type='date'], input[type='number'] {
        width: 200px
}
    }
button {
    cursor: pointer;
}
textarea {
    border: 1px solid #1F1410;
    display: block;
    width: 100%;
    min-height: 14rem;
    padding: .5rem;
}
strong {
    font-weight: 600;
}
em {
    font-style: italic;
}
p {
    font-size: 1.8rem;
    color: #1F1410;
    margin-bottom: 3.2rem;
    line-height: 2.8rem;
}
ul {
    list-style: none;
}
li {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
}
