.search-form {
    position: relative;
}
.search-input[type='text'] {
    background-color: #FAFAFA;
    padding: 1.5rem 2rem 1.5rem 6rem;
    width: 25rem;
    border: none;
}
.search-button[type='button'] {
    background: url('/images/search-button.svg') no-repeat center;
    display: block;
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 1rem;
    left: 1rem;
    border: none;
    cursor: pointer;
}
