.gallery {
    img {
        height: '20rem';
    }
}
.cards {
    display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-bottom: 1.6rem;
	margin-right: calc(3.2rem * -1)
}
.cards>* {
		width: 100%;		
		max-height: 800px;
		padding-right: 3.2rem
	}
@media (min-width: 801px) {
.cards>* {
			max-height: 600px;
			max-width: 33.33%
	}
		}
@media (min-width: 801px) and (max-width: 1151px) {
.cards>* {
			max-width: 50%
	}
		}
@media (min-width: 801px) {
.wrap {
		display: flex
}
		.wrap > div {
			flex: 1 1 0;
		}
		.wrap > div + div {
			flex: 2 1 0;
			margin-left: 3.2rem;
		}
	}
.wrap {
	margin-bottom: 4.8rem
}
.wrap .photo{
		display: flex;
		justify-content: center;
		align-items: center
	}
.wrap .photo > * {
			max-width: 100%;
		}
