.root {
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.photo {
    background-color: #FAFAFA;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 1.6rem
}

.photo:before {
        content: "";
        display: block;
        padding-bottom: 60%;
    }

.text {
    overflow: hidden;
    height: auto;
}

.h4 {
	font-family: 'Work Sans', sans-serif;
    font-size: 3.2rem;
    padding: 1.6rem 0;
    text-transform: uppercase
}

.h4 a:hover {
        color: #DAD978;
    }

.read {
    font-size: 1.6rem;
    text-transform: uppercase;
    position: relative;
    font-weight: 600;
    letter-spacing: 2px;
    margin-top: 8px
}

.read:after {
        content:'';
        width: 2rem;
        height: 1.2rem;
        background: url('/images/arrow-right.svg') no-repeat center;
        background-size: contain;
        position: absolute;
        top: 4px;
    }

.read:hover:after {
        background: url('/images/arrow-right-hover.svg') no-repeat center;
    }

.fade {
    position: relative
}

.fade:after {
        content:'';
        position: absolute;
        background: linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
        width: 100%;
        height: 42px;
        bottom: -8px;
    }

.tags {
    display: flex;
    flex-wrap: wrap;
}

.tags .tag-name {
    background: #F2F255;
    padding: .5rem 1rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    margin: 1rem 1rem 0 0;
    font-weight: 500;
    display: inline-block
}

.tags .tag-name:hover {
        background: #1F1410;
        color: #F2F255;
    }
