.menu {
    display: flex;
    justify-content: flex-end;
    list-style-type: none
}
.menu li {
        margin-bottom: 0;
        color: #1F1410;
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: 500;
        margin-left: 1.6rem
    }
@media (min-width: 801px) and (max-width: 960px ) {
    .menu li {
            margin-left: 0
    }
        }
@media (min-width: 801px) and (max-width: 1151px) {
    .menu li {
            font-size: 1.2rem
    }
        }
.menu li > a {
            padding: 0.4rem 0.8rem;
            display: inline-block;
            border-bottom: solid 5px #fff
        }
@media (min-width: 801px) and (max-width: 1152px ) {
    .menu li > a {
                padding: 0.4rem
        }
            }
.menu li > a.active {
                border-bottom: solid 5px #DEDD19;
            }
.menu li a:hover {
            border-bottom: solid 5px #DEDD19;
        }
@media (min-width: 801px) and (max-width: 1152px ) {
    .menu li {
            font-size: 1.4rem
    }
        }