.grid {
    flex-wrap: wrap;
    margin: 3.2rem 0
}
@media (min-width: 801px) {
.grid {
        display: flex
}
        .grid > div {
            margin: 0 3.2rem 3.2rem 0;
        }
    }
.grid.data {
        margin: 3.2rem 0 1.6rem 0
    }
.grid.data > div {
            margin: 0 3.2rem 1.6rem 0
        }
@media (min-width: 801px) {
.grid.data > div {
                margin: 0 3.2rem 0 0
        }
            }
.file-field {
    width: 200px
}
.file-field input{
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
input[type='radio'] {
    margin-right: 0.8rem;
}
section .h2 {
    margin-bottom: 4.8rem;
}
input[type='checkbox'] {
    margin-right: 0.8rem;
}
.field {
    margin-bottom: 4.8rem
}
.field.small > div {
        margin-bottom: 1.6rem;
    }
.checkbox label {
        display: flex;
    }
.checkbox input[type="checkbox"] {
        opacity: 0;
        margin-left: -17px;
    }
.checkbox .check {
        position: relative;
        display: inline-block;
        padding-left: 22px;
        margin: 0;
    }
.checkbox .check::before,
.checkbox .check::after {
    position: absolute;
    content: "";
    display: inline-block;
}
.checkbox .check::before{
    height: 16px;
    width: 16px;
    border: 1px solid #1F1410;
    left: 0;
    top: 4;
}
.checkbox .check::after {
    height: 5px;
    width: 9px;
    border-left: 2px solid #1F1410;
    border-bottom: 2px solid #1F1410;
    transform: rotate(-45deg);
    left: 4px;
    top: 4px;
}
.checkbox input[type="checkbox"] + .check::after {
    content: none;
}
.checkbox input[type="checkbox"]:checked + .check::after {
    content: "";
}
.radio label {
  position: relative;
}
.radio input {
  position: absolute;
  margin: 5px;
  padding: 0;
  opacity: 0;
}
.radio .label-visible {
  margin-left: 2em;
  margin-bottom: 0;
}
.fake-radio {
  position: absolute;
  display: block;
  top: 0;
  left: 3px;
  width: 16px;
  height: 16px;
  border: 1px solid #1F1410;
  background-color: white;
}
.fake-radio:after {
  content: "";
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background: #1F1410;
  transform: translateX(-50%) translateY(-50%);
}
.fake-radio { border-radius: 50%; }
.fake-radio:after { border-radius: 50%; }
input[type="radio"]:checked + span .fake-radio:after { display: block; }