.h3 {
	text-transform: uppercase;
	font-weight: 600;
	font-size: 1.6rem;
	position: relative;
	display: inline-block;
	cursor: pointer;
	letter-spacing: 2px
}
.h3:after {
		content:'';
		width: 1.5rem;
		height: 1rem;
		background: url('/images/arrow-top.svg') no-repeat center;
		background-size: contain;
		position: absolute;
	    right: -2.5rem;
	    top: .5rem;
	}
.h3.show:after {
		background: url('/images/arrow-bottom.svg') no-repeat center;
		top: .7rem;
	}
.filters {
	background: #FAFAFA;
	padding: 2rem;
	margin-bottom: 4.8rem
}
.filters .list li {
		text-transform: uppercase;
		display: inline-block;
		margin: 2rem 2rem 0 0;
		cursor: pointer;
		font-weight: 500;
		font-size: 1.4rem
	}
.filters .list li.selected {
			background: #F2F255;
			padding: .5rem;
		}
.projects {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 1.6rem;
	margin-right: calc(3.2rem * -1)
}
.projects>* {
		width: 100%;
		max-height: 800px;
		margin-right: 3.2rem
	}
@media (min-width: 801px) {
.projects>* {
			max-height: 600px;
			width: 30%;
			min-width: 250px;
			margin-right: 3.2rem
	}
		}
@media (min-width: 801px) and (max-width: 1151px) {
.projects>* {
			width: 45%;
			min-width: 250px;
			margin-right: 3.2rem
	}
		}
.pagination {
	display: flex;
	justify-content: center
}
.pagination ul {
	    display: flex;
		position: relative
	}
.pagination ul li {
	    	font-weight: 600;
	    	padding: 1rem 2rem;
			cursor: pointer;
	    }
.pagination .selected {
		border-bottom: solid 4px #DEDD19;
	}
.pagination .next {
		width: 2rem;
		height: 1.5rem;
		background: url('/images/arrow-right.svg') no-repeat center;
		background-size: contain;
		margin-bottom: 0;
		padding: 0;
	    position: absolute;
		top: 1.4rem;
		right: -2rem;
	}
.pagination .prev {
		width: 2rem;
		height: 1.5rem;
		background: url('/images/arrow-left.svg') no-repeat center;
		background-size: contain;
		margin-bottom: 0;
		padding: 0;
	    position: absolute;
		top: 1.4rem;
		left: -2rem;
	}
@media (min-width: 801px) {
.wrapper {
        display: flex
}
    }
.wrapper .main-content {
    	flex-basis: 60%;
        padding-top: 3.2rem;
    }
.tags {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 1.6rem 0;
}
.tags span {
	    background: #F2F255;
	    padding: .5rem;
	    font-size: 1.4rem;
	    text-transform: uppercase;
	    margin-right: 1rem;
	    font-weight: 500;
	}
.tags .tag-state {
		background: none;
	}
.date {
    font-size: 1.4rem;
    margin-bottom: 0;
}
.epigraph {
    font-size: 2.8rem;
}
.sidebar {
    flex: 1 1 0;
    padding-top: 3.2rem
}
@media (min-width: 801px) {
.sidebar {
        margin-left: 4.8rem
}
    }
.sidebar .more .title {
			font-weight: 600;
			letter-spacing: 2px;
			text-transform: uppercase;
		}
.sidebar .date {
        margin-left: 1.6rem;
    }
.photo {
	display: flex;
	justify-content: center;
	align-items: center;
    width: 100%;
	overflow: hidden
}
.photo > * {
		width: 100%;
	}
.items {
    cursor: pointer
}
@media (min-width: 801px) {
.items {
        display: flex
}
        .items .item {
            margin-right: 5rem;
            max-height: 8rem;
        }
        .items .item:nth-child(5n) {
            margin-right: 0;
        }
    }
.items {
    margin-bottom: 4.8rem;
    text-align: center
}
.items .item {
        max-width: 100%;
    }
.list {
    display: flex;
    margin-bottom: 4.8rem
}
.list > div {
        flex: 0 1 32%;
		margin-right: 3rem;
        max-height: 500px;
    }
.list >div:nth-child(3n) {
        margin-right: 0;
    }
@media (min-width: 801px) {
.buttons {
		display: flex;
}
		.buttons > a + a {
			margin-left: 3.2rem;
		}
	}
.buttons {
	margin-bottom: 4.8rem;
}
.button-primary {
	display: block;
	border: solid 1px #1F1410;
	padding: 1.6rem 3.2rem;
	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 2px;
	font-size: 1.4rem;
	margin-bottom: 1.6rem
}
.button-primary:hover {
		background: #1F1410;
		color: #FFF;
	}
.newsletter {
	background: #FAFAFA;
	padding-top: 3.2rem;
}
@media (min-width: 801px) {
.form {
		display: flex;
		align-items: flex-end
}
		.form > div + div {
			margin-left: 3.2rem;
		}
	}
.form .field {
		margin-bottom: 1.6rem;
	}
.h4 {
	font-size: 2.8rem;
	font-weight: 500;
	margin-bottom: 1.6rem
}
@media (max-width: 800px) {
.h4 {
		font-size: 1.8rem
}
	}
.label {
	display: block;
	margin-bottom: 0.8rem;
}
.input, .select {
	border: solid 1px #1F1410;
	padding: 0 0.8rem;
	min-width: 30rem;
	width: 100%;
	height: 4rem;
}
.button-send {
	width: 100%;
	background: #1F1410;
	color: #FFF;
	padding: 0 4.8rem;
	height: 4rem;
	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 2px;
	font-size: 1.4rem;
	cursor: pointer;
}
.block {
	margin-bottom: 12rem;
}