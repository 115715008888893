
    .root section ul {
        list-style-type: none;
    }
.root h2 {
        font-family: 'Work Sans', sans-serif;
        margin-bottom: 1.6rem;
        color: #1F1410;
        font-size: 4rem;
        text-transform: uppercase;
    }
.root h3 {
        font-family: 'Work Sans', sans-serif;
        margin-bottom: 1.6rem;
        font-size: 2.8rem;
        font-weight: 500;
        text-transform: uppercase;
    }
.root img {
        display: block;
        width: 100%;
    }
.root a {
        font-weight: bold;
    }
.root ol {
        list-style: none;
        counter-reset: custom-counter;
    }
.root ol li {
        counter-increment: custom-counter;
    }
.root ol p::before {
        content: counter(custom-counter) ". ";
        font-weight: bold;
    }
.root .underline {
        text-decoration: underline;
    }
