
    @media (min-width: 801px) {
.wrapper {
        display: flex
}
    }
.wrapper .main-content {
        flex-basis: 60%;
        padding-top: 3.2rem;
    }
.date {
    font-size: 1.4rem;
    margin-bottom: 0;
}
.epigraph {
    font-size: 2.8rem;
}
.sidebar {
    flex: 1 1 0;
    padding-top: 3.2rem;
    margin-bottom: 4.8rem
}
@media (min-width: 801px) {
.sidebar {
        margin-left: 4.8rem
}
    }
.sidebar .more .title {
        font-weight: bold;
        letter-spacing: 2px;
        text-transform: uppercase;
        display: block;
    }
.sidebar .date {
        margin-left: 1.6rem;
    }
.photo {
	display: flex;
	justify-content: center;
	align-items: center;
    width: 100%;
    overflow: hidden
}
.photo > * {
        width: 100%;
    }
iframe {
    max-width: 100%;
}
.items {
    cursor: pointer
}
@media (min-width: 801px) {
.items {
        display: flex
}
        .items .item {
            margin-right: 1rem;
            max-height: 15rem;
        }
        .items .item:nth-child(5n) {
            margin-right: 0;
        }
    }
.items {
    margin-bottom: 4.8rem;
    text-align: center
}
.items .item {
        max-width: 100%;
    }
@media (min-width: 801px) {
.list {
        display: flex
}
    }
.list {
    margin-bottom: 4.8rem
}
.list > div {
        flex: 0 1 32%;
        margin-right: 3rem;
        max-height: 500px;
    }
.main-content {
    margin-bottom: 18rem;
}
