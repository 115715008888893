.grid {
    flex-wrap: wrap;
    margin: 3.2rem 0
}

@media (min-width: 801px) {

.grid {
        display: flex
}
        .grid > div {
            margin: 0 3.2rem 3.2rem 0;
        }
    }

.grid.group {
        margin: 3.2rem 0 1.6rem 0
    }

.grid.group > div {
            margin: 0 3.2rem 1.6rem 0
        }

@media (min-width: 801px) {

.grid.group > div {
                margin: 0 3.2rem 0 0
        }
            }

.file-field {
    max-width: 500px
}

.file-field input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

.file-field .label > div {
        display: flex
    }

.file-field .label > div > div {
            margin: 0;
        }

.file-field .label > div .file-name {
            flex-grow: 1;
            display: flex;
            align-items: center;
            padding: 0.8rem 1.6rem;
            border: 1px solid #1F1410;
        }

.file-field.error .label > div > div {
            border-color: #A4212A;
            border-width: 2px;
        }

section .h2 {
    margin-bottom: 4.8rem;
}

input[type='checkbox'] {
    margin-right: 0.8rem;
}

.field {
    margin-bottom: 4.8rem
}

.field.small > div {
        margin-bottom: 1.6rem;
    }

.field.error input,
        .field.error textarea,
        .field.error .check::before {
            border-color: #A4212A;
            border-width: 2px;
        }

.list {
    margin-top: 1.6rem;
    list-style-position: inside
}

.list.small > div {
        margin-bottom: 1.6rem;
    }

.list .file-field {
        margin-left: 1.6rem;
    }

.checkbox label {
        display: flex;
    }

.checkbox input {
        display: none;
    }

.checkbox .check {
        position: relative;
        display: inline-block;
        padding-left: 22px;
        margin: 0;
    }

.checkbox .check::before,
    .checkbox .check::after {
        position: absolute;
        content: "";
        display: inline-block;
    }

.checkbox .check::before {
        height: 16px;
        width: 16px;
        border: 1px solid #1F1410;
        left: 0;
    }

.checkbox .check::after {
        height: 5px;
        width: 9px;
        border-left: 2px solid #1F1410;
        border-bottom: 2px solid #1F1410;
        transform: rotate(-45deg);
        left: 4px;
        top: 4px;
    }

.checkbox input[type="checkbox"] + .check::after {
        content: none;
    }

.checkbox input[type="checkbox"]:checked + .check::after {
        content: "";
    }

.checkbox.error .check::before {
            border-color: #A4212A;
        }

.radio label {
      position: relative;
    }

.radio input {
      position: absolute;
      margin: 5px;
      padding: 0;
      opacity: 0;
    }

.radio .label-visible {
      margin-left: 2em;
      margin-bottom: 0;
    }

.radio .fake-radio {
      position: absolute;
      display: block;
      top: 0;
      left: 3px;
      width: 16px;
      height: 16px;
      border: 1px solid #1F1410;
      background-color: white;
    }

.radio .fake-radio:after {
      content: "";
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      background: #1F1410;
      transform: translateX(-50%) translateY(-50%);
    }

.radio .fake-radio { border-radius: 50%; }

.radio .fake-radio:after { border-radius: 50%; }

.radio input[type="radio"]:checked + span .fake-radio:after {
        display: block;
    }

.radio.error .fake-radio {
            border-color: #A4212A;
            border-width: 2px;
        }

.submit {
    display: flex
}

.submit > button {
        margin: 0;
    }

.submit > div {
        display: flex;
        align-items: center;
        font-weight: bold;
    }

.submit > .error-message {
        color: #A4212A;
        margin-left: 1.6rem;
    }

.submit > .success-message {
        margin-left: 1.6rem
    }

.submit > .success-message > a {
            margin-left: 0.8rem;
        }

.success-message {
    display: flex;
    margin: 0 auto;
    padding: 3.2rem;
    border: 1px solid #1F1410;
    text-align: center;
    flex-direction: column;
    max-width: 40rem;
    font-size: 1.8rem
}

.success-message .next {
        display: block;
        margin-top: 4.8rem;
    }
